/**
 * CkVehicleTypeSelection Component
 *
 * `CkVehicleTypeSelection` is a custom component.
 * It wraps the Ant Design's Form.Item component and renders three buttons for 
 * vehicle type selection namely - 'Vehicle', 'Truck' and 'Motocycle' 
 * each represented with an icon using `CkIcon` component.
 *
 * @component
 * @example
 * const vehicleButtonProps = { selected: true, onClick: () => alert('You clicked vehicle') };
 * const truckButtonProps = { selected: false, onClick: () => alert('You clicked truck') };
 * const motorcycleButtonProps = { selected: false, onClick: () => alert('You clicked motorcycle') };
 * <CkVehicleTypeSelection
 *   className="my-class" 
 *   vehicleButtonProps={vehicleButtonProps} 
 *   truckButtonProps={truckButtonProps} 
 *   motorcycleButtonProps={motorcycleButtonProps} 
 * />
 *
 * @extends {FormItemProps}
 *
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {CkVehicleTypOptionProps} vehicleButtonProps - Optional: Additional properties for the 'Vehicle' button
 * @property {CkVehicleTypOptionProps} truckButtonProps - Optional: Additional properties for the 'Truck' button
 * @property {CkVehicleTypOptionProps} motorcycleButtonProps - Optional: Additional properties for the 'Motorcycle' button
 * @property {"minimal"|"full"} size - Optional: Determines the size of the Form Item, defaults to "minimal"
 */
import React, { FC } from "react";
import { CkIcon } from "../../atoms";
import { Form, FormItemProps, Button, ButtonProps } from "antd";
import "./styles.css";

export interface CkVehicleTypOptionProps extends ButtonProps {
  /**
   * selected option will be marked as active
   */
  selected: boolean;
}
export interface CkVehicleTypeSelectionProps extends FormItemProps {
  /**
   * Optional: Additional CSS classes to apply
   */
  className?: string;
  /**
   * Optional: Additional properties for the 'Vehicle' button
   */
  vehicleButtonProps?: CkVehicleTypOptionProps;
  /**
   * Optional: Additional properties for the 'Truck' button
   */
  truckButtonProps?: CkVehicleTypOptionProps;
  /**
   * Optional: Additional properties for the 'Motorcycle' button
   */
  motocycleButtonProps?: CkVehicleTypOptionProps;
  /**
   * Optional: Determines the size of the Form Item, defaults to "minimal". 
   * Full option will force buttons to cover all available width
   */
  size?: "minimal" | "full";
}

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * CkVehicleTypOptionProps extends AntD Button, see full docs on https://4x.ant.design/components/button/
 */
const CkVehicleTypeSelection: FC<CkVehicleTypeSelectionProps> = ({
  className,
  vehicleButtonProps,
  truckButtonProps,
  motocycleButtonProps,
  size = "minimal",
  ...props
}) => (
  <Form.Item
    className={[
      "ck-vehicle-type-selection",
      `size-${size}`,
      ...(className ? [className] : []),
    ].join(" ")}
    {...props}
  >
    <Button
      className={
        vehicleButtonProps && vehicleButtonProps?.selected ? "selected" : ""
      }
      icon={<CkIcon name={"car"} width="" height="" fill="" />}
      {...(vehicleButtonProps || {})}
    />
    <Button
      className={
        truckButtonProps && truckButtonProps?.selected ? "selected" : ""
      }
      icon={<CkIcon name={"truck"} width="" height="" fill="" />}
      {...(truckButtonProps || {})}
    />
    <Button
      className={
        motocycleButtonProps && motocycleButtonProps?.selected ? "selected" : ""
      }
      icon={<CkIcon name={"motorycle"} width="" height="" fill="" />}
      {...(motocycleButtonProps || {})}
    />
  </Form.Item>
);

export default CkVehicleTypeSelection;
