/**
 * CkToggleableTags Component
 *
 * `CkToggleableTags` is a custom component designed for displaying a group of selectable or toggleable tags/labels.
 * It allows the user to select multiple tags from a group and actively highlights the selected ones.
 *
 * @component
 * @example
 * const options = [{label: "Option 1", value: "one"}, {label: "Option 2", value: "two"}];
 * const [selectedTags, setSelectedTags] = useState([]);
 * <CkToggleableTags label="My Label" options={options} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
 *
 * @property {string} label - Optional: A label for the group of tags
 * @property {string} className - Optional: Additional CSS classes to apply for the component
 * @property {string} optionClassName - Optional: Additional CSS classes for each option of tags
 * @property {CkToggleableTagsOptionProps[]} options - The options to show in the tag group, specified as an array of objects where each object has a `label` and `value`
 * @property {string[]} selectedTags - Current selected tags in the group. It should be the `value` property of an `options` object
 * @property {Function} setSelectedTags - A function to update the state of `selectedTags` in the parent component
 * @property {boolean} removeOptions - Optional: If true, an selectedTags to remove them when clicked
 */
import React, { FC } from "react";
import { Button } from "antd";
import { CkIcon, CkIconNames } from "../../atoms";
import "./styles.css";

/**
 * CkToggleableTagsOptionProps
 *
 * This interface represents the shape of an option used in the CkToggleableTags component.
 * Each option constitutes a tag that the user can interact with.
 *
 * @interface
 *
 * @example
 * // Use of CkToggleableTagsOptionProps
 * const tagOption: CkToggleableTagsOptionProps = { label: 'Option 1', value: '1' };
 */
interface CkToggleableTagsOptionProps {
  /**
   * The unique value attributed to the tag option. It's typically used as a key when creating a list of `CkToggleableTags` elements, and will be the value included in the `selectedTags` array if this `CkToggleableTagsOptionProps` is selected.
   */
  value: string;
  /**
   * The tag's visual label representing the tag to the end user.
   */
  label: string;
  /**
   * The tag's con name to show in the button.
   */
  iconName?: CkIconNames;
}

export interface CkToggleableTagsProps {
  /**
   * Optional: A label for the group of tags
   */
  label?: string;
  /**
   * Optional: Additional CSS classes to apply for the component
   */
  className?: string;
  /**
   * Optional: Additional CSS classes for each option of tags
   */
  optionClassName?: string;
  /**
   * The options to show in the tag group, specified as an array of objects where each object has a `label` and `value`
   */
  options: CkToggleableTagsOptionProps[];
  /**
   * Optional: Combined with removeOptions will help remove deselected option
   */
  setOptions?: Function;
  /**
   * Current selected tags in the group. It should be the `value` property of an `options` object.
   */
  selectedTags: string[];
  /**
   * A function to update the state of `selectedTags` in the parent component
   */
  setSelectedTags: Function;
  /**
   * If is set to true, option will be removed on cancel option. Set option must be provided for this to work.
   */
  removeOptions?: boolean;
}
const CkToggleableTags: FC<CkToggleableTagsProps> = ({
  label,
  className,
  optionClassName,
  options,
  selectedTags,
  setSelectedTags,
}) => {
  const toogleOption = (option: CkToggleableTagsOptionProps) => {
    if (selectedTags.includes(option.value)) {
      setSelectedTags((prevState) =>
        prevState.filter((current) => current !== option.value)
      );
    } else {
      setSelectedTags((prevState) => [...prevState, option.value]);
    }
  };

  const OptionButton = ({
    option,
    selected,
    iconName,
  }: {
    option: CkToggleableTagsOptionProps;
    selected: boolean;
    iconName?: CkIconNames;
  }) => (
    <Button
      className={["ck-tag", ...(selected ? ["selected"] : [])].join(" ")}
      onClick={() => toogleOption(option)}
    >
      {iconName && <CkIcon name={iconName} width="" height="" fill="" />}
      {option.label}
      {selected && <CkIcon name="close" width="" height="" fill="" />}
    </Button>
  );

  return (
    <div className="ck-toggleable-tags">
      {label && <p className="label">{label}</p>}
      <div className="tags-container">
        {options &&
          options.map((option) => (
            <OptionButton
              key={option.value}
              option={option}
              selected={selectedTags.includes(option.value)}
              iconName={option?.iconName}
            />
          ))}
      </div>
    </div>
  );
};

export default CkToggleableTags;
